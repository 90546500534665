import Velocity from  'velocity-animate';

const questionHandler = () => {
	if(!document.getElementById('QuestionForm')) {
		return false
	}

	let currentQuestion;
	const prevQuestionButton = document.getElementById('prevQuestionButton')

	const activeQuestionHandler = () => {
		const _window = window;
		let viewPortMiddlePoint =  _window.innerHeight / 2;

		const questions = document.querySelectorAll('.question-section');
		const questionActiveClass = 'question-section--active'
		if(!questions.length){
		  return false;
		}
		// Focus question on scroll
		_window.addEventListener('scroll', ( (e) => {
			e.stopPropagation();
			// magic number
			viewPortMiddlePoint = (_window.innerHeight / 1.8);

			for (let question of questions) {
			  const _this = question;
			  if(viewPortMiddlePoint >= _this.getBoundingClientRect().top && viewPortMiddlePoint <= _this.getBoundingClientRect().bottom) {
				  _this.classList.add(questionActiveClass);
				  // focusInput(_this);
				  updateCurrentQuestion(_this.getAttribute('data-question'));
			  } else {
				  _this.classList.remove(questionActiveClass);
			  }
			}
		  }), false);
	}
	const sliderValueUpdates = () => {
		const __sliders = document.querySelectorAll('.form-input-wrap__range-slider__input')
		for(let __slider of __sliders) {
			const sliderValueHolder = document.querySelector('[data-sliderValueHolder="'+__slider.id+'"]')

			__slider.addEventListener('change', ( (e) => {
							sliderValueHolder.innerHTML = __slider.value
			}), false)

		}
	}
	const updateCurrentQuestion = (currentId) => {
		if(!currentId || !prevQuestionButton) {
			return false
		}
		if(currentId > 1) {
			currentQuestion = currentId - 1
			prevQuestionButton.classList.add('site-footer__previous-question--active')
		}
		else {
			currentQuestion = 'false'
			prevQuestionButton.classList.remove('site-footer__previous-question--active')

		}

		prevQuestionButton.setAttribute('data-prevQuestion', currentQuestion)
	}
	const prevButtonHandler = () => {
		prevQuestionButton.addEventListener('click', ( () => {
			const prevQuestion = prevQuestionButton.getAttribute('data-prevQuestion')

			if(prevQuestion !== 'false') {
				const __prevQuestion = document.getElementById('question'+ prevQuestion)
				//const questionElementOffset = (__prevQuestion.clientHeight / 2)
			 	Velocity(__prevQuestion, 'scroll', { duration: 500, offset:-100 })
			}

		}), false)
	}
	const nextQuestion = (__nextQuestionId) => {
		const viewPortMiddle = (window.innerHeight / 2)
		const __nextQuestion = document.getElementById('question'+ __nextQuestionId)

		const questionElementOffset = __nextQuestion.clientHeight > window.innerHeight ? -100 : -(viewPortMiddle  - __nextQuestion.clientHeight / 2)
		Velocity(__nextQuestion, 'scroll', { duration: 500, offset:questionElementOffset })
	}
	const nextQuestionHandler = () => {
		const nextQuestionButtons = document.querySelectorAll('.next-question__button')

		for(let __button of nextQuestionButtons) {

			__button.addEventListener('click', ( (e) => {
				nextQuestion(__button.getAttribute('data-nextQuestion'))
			}), false)
		}
	}
	const startQuestionnaireHandler = () => {
			const startSection = document.getElementById('startFormSection')
			const formElement = document.getElementById('formHolder')
			const startButton = document.getElementById('startFormButton')

			if(!startSection || !startButton || !formHolder) {
				return false
			}

			startButton.addEventListener('click', ((e) => {
				nextQuestion(1)


			}), false)

	}
	const updateAnsweredQuestionsTicker = () => {

		const questionProgressHolder = document.getElementById('questionProgressHolder')
		if(!questionProgressHolder) {
	    	return false;
	    }
	    const validQuestions = document.querySelectorAll('.question[data-valid="true"]');

		if(parseInt(questionProgressHolder.innerHTML) !== validQuestions.length) {
			questionProgressHolder.classList.add('pre-animation')
		    questionProgressHolder.innerHTML = validQuestions.length;
			setTimeout( () => {
		    	questionProgressHolder.classList.remove('pre-animation')
			}, 300)
		}

	}

	const validateQuestionFn = (__validity, __question) => {
		const questionValidClass = 'question--valid'

		if(__validity) {
	        __question.classList.add(questionValidClass);
	    } else {
	        __question.classList.remove(questionValidClass);
	    }

	    __question.setAttribute('data-valid', __validity);
			formValidityHandler()
	    updateAnsweredQuestionsTicker();
	}

	const questionValidityHandler = () => {
		//
		const __questions = document.querySelectorAll('.question')

		for(let __question of __questions) {
			const __inputs = __question.querySelectorAll('input.form-input__element')
			const multipleRadioButtonsRequired = __question.getAttribute('data-multiple')
			let multipleInputList = []
			let multipleValidInputs = []

			for(let __input of __inputs) {
				let validity = false;

					__input.addEventListener('change', ( () => {
						if(__input.type === 'radio') {
							validity = __input.checked

            	} else if(__input.type === 'range') {
							validity = true
						}

            validateQuestionFn(validity, __question)
            }), false)

				}// for __inputs

			} // for __questions

		}

	const formValidityHandler = () => {
		//
		let questionsValidity = false
		let formValidity = false

		const __allRequiredQuestions = [];
		const __validContactDetails = [];
		const __validQuestions = [];

	const totalQuestions = document.querySelectorAll('.question')
	const validQuestions = document.querySelectorAll('.question[data-valid="true"]')
	const requiredQuestions = document.querySelectorAll('.question[data-required="false"]')
	const contactDetailInputs = document.querySelectorAll('.page-section__personal-details input')
	const submitFormButton = document.getElementById('submitFormButton')

	for(let __input of contactDetailInputs) {
		if(__input.validity.valid) {
			__validContactDetails.indexOf(__input.id) < 0 ? __validContactDetails.push(__input.id) : null
		} else {
			__validContactDetails.indexOf(__input.id)  > -1 ? __validContactDetails.splice( __validContactDetails.indexOf(__input.id) , 1 ) : null
		}
			__validContactDetails.length === contactDetailInputs.length ? formValidity = true : formValidity = false;
	}

	for(let __question of totalQuestions) {
		const questionValidity = __question.getAttribute('data-valid')
		const questionRequired = __question.getAttribute('data-required')
		__allRequiredQuestions.indexOf(__question.id) < 0 ? __allRequiredQuestions.push(__question.id) : null

		if(questionValidity === 'true' && questionRequired === 'true') {
			__validQuestions.indexOf(__question.id) < 0 ? __validQuestions.push(__question.id) : null
		} else if(questionRequired === 'false') {
			__allRequiredQuestions.indexOf(__question.id)  > -1 ? __allRequiredQuestions.splice( __allRequiredQuestions.indexOf(__question.id) , 1 ) : null
		} else {
			__validQuestions.indexOf(__question.id)  > -1 ? __validQuestions.splice( __validQuestions.indexOf(__question.id) , 1 ) : null // dont know if this is needed...
		}
		__validQuestions.length === __allRequiredQuestions.length ? questionsValidity = true : questionsValidity = false;
	}


	submitFormButton.disabled = !(questionsValidity && formValidity)
	}


	for (let __input of document.querySelectorAll('.page-section__personal-details input')) {
		__input.addEventListener('keyup', ( (e) => {
			formValidityHandler()
		}), false)
		__input.addEventListener('touchend', ( (e) => {
			formValidityHandler()
		}), false)
	}
	// inits
	questionValidityHandler()
	startQuestionnaireHandler()
	prevButtonHandler()
	activeQuestionHandler()
	sliderValueUpdates()
	nextQuestionHandler()
}

export default questionHandler;
